import React from "react";

export default function Calendar({className}) {
  return (
      // size is customizable
      <svg width="20" height="20" viewBox="0 0 26 26"
           fill="none" xmlns="http://www.w3.org/2000/svg"
           className={className}>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M21.2333 10.8333H22.9667C23.205 10.8333 23.4 11.0283 23.4 11.2667V13C23.4 13.2383 23.205 13.4333 22.9667 13.4333H21.2333C20.995 13.4333 20.8 13.2383 20.8 13V11.2667C20.8 11.0283 20.995 10.8333 21.2333 10.8333ZM21.6667 12.5667H22.5333V11.7H21.6667V12.5667Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12.5667 19.5C12.3283 19.5 12.1333 19.695 12.1333 19.9333V21.6667C12.1333 21.905 12.3283 22.1 12.5667 22.1H14.3C14.5383 22.1 14.7333 21.905 14.7333 21.6667V19.9333C14.7333 19.695 14.5383 19.5 14.3 19.5H12.5667ZM13 21.2333V20.3667H13.8667V21.2333H13Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8.23333 10.8333H9.96667C10.205 10.8333 10.4 11.0283 10.4 11.2667V13C10.4 13.2383 10.205 13.4333 9.96667 13.4333H8.23333C7.995 13.4333 7.8 13.2383 7.8 13V11.2667C7.8 11.0283 7.995 10.8333 8.23333 10.8333ZM8.66667 12.5667H9.53333V11.7H8.66667V12.5667Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3.9 10.8333C3.66167 10.8333 3.46667 11.0283 3.46667 11.2667V13C3.46667 13.2383 3.66167 13.4333 3.9 13.4333H5.63333C5.87167 13.4333 6.06667 13.2383 6.06667 13V11.2667C6.06667 11.0283 5.87167 10.8333 5.63333 10.8333H3.9ZM4.33333 12.5667V11.7H5.2V12.5667H4.33333Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12.5667 10.8333H14.3C14.5383 10.8333 14.7333 11.0283 14.7333 11.2667V13C14.7333 13.2383 14.5383 13.4333 14.3 13.4333H12.5667C12.3283 13.4333 12.1333 13.2383 12.1333 13V11.2667C12.1333 11.0283 12.3283 10.8333 12.5667 10.8333ZM13 11.7V12.5667H13.8667V11.7H13Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M18.6333 10.8333H16.9C16.6617 10.8333 16.4667 11.0283 16.4667 11.2667V13C16.4667 13.2383 16.6617 13.4333 16.9 13.4333H18.6333C18.8717 13.4333 19.0667 13.2383 19.0667 13V11.2667C19.0667 11.0283 18.8717 10.8333 18.6333 10.8333ZM18.2 12.5667H17.3333V11.7H18.2V12.5667Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3.46667 15.6C3.46667 15.3617 3.66167 15.1667 3.9 15.1667H5.63333C5.87167 15.1667 6.06667 15.3617 6.06667 15.6V17.3333C6.06667 17.5717 5.87167 17.7667 5.63333 17.7667H3.9C3.66167 17.7667 3.46667 17.5717 3.46667 17.3333V15.6ZM4.33333 16.0333V16.9H5.2V16.0333H4.33333Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9.96667 15.1667H8.23333C7.995 15.1667 7.8 15.3617 7.8 15.6V17.3333C7.8 17.5717 7.995 17.7667 8.23333 17.7667H9.96667C10.205 17.7667 10.4 17.5717 10.4 17.3333V15.6C10.4 15.3617 10.205 15.1667 9.96667 15.1667ZM9.53333 16.9H8.66667V16.0333H9.53333V16.9Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12.5667 15.1667H14.3C14.5383 15.1667 14.7333 15.3617 14.7333 15.6V17.3333C14.7333 17.5717 14.5383 17.7667 14.3 17.7667H12.5667C12.3283 17.7667 12.1333 17.5717 12.1333 17.3333V15.6C12.1333 15.3617 12.3283 15.1667 12.5667 15.1667ZM13 16.0333V16.9H13.8667V16.0333H13Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M18.6333 15.1667H16.9C16.6617 15.1667 16.4667 15.3617 16.4667 15.6V17.3333C16.4667 17.5717 16.6617 17.7667 16.9 17.7667H18.6333C18.8717 17.7667 19.0667 17.5717 19.0667 17.3333V15.6C19.0667 15.3617 18.8717 15.1667 18.6333 15.1667ZM18.2 16.9H17.3333V16.0333H18.2V16.9Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3.9 19.5H5.63333C5.87167 19.5 6.06667 19.695 6.06667 19.9333V21.6667C6.06667 21.905 5.87167 22.1 5.63333 22.1H3.9C3.66167 22.1 3.46667 21.905 3.46667 21.6667V19.9333C3.46667 19.695 3.66167 19.5 3.9 19.5ZM4.33333 21.2333H5.2V20.3667H4.33333V21.2333Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9.96667 19.5H8.23333C7.995 19.5 7.8 19.695 7.8 19.9333V21.6667C7.8 21.905 7.995 22.1 8.23333 22.1H9.96667C10.205 22.1 10.4 21.905 10.4 21.6667V19.9333C10.4 19.695 10.205 19.5 9.96667 19.5ZM9.53333 21.2333H8.66667V20.3667H9.53333V21.2333Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M21.2333 15.1667H22.9667C23.205 15.1667 23.4 15.3617 23.4 15.6V17.3333C23.4 17.5717 23.205 17.7667 22.9667 17.7667H21.2333C20.995 17.7667 20.8 17.5717 20.8 17.3333V15.6C20.8 15.3617 20.995 15.1667 21.2333 15.1667ZM21.6667 16.9H22.5333V16.0333H21.6667V16.9Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M22.9667 19.5H21.2333C20.995 19.5 20.8 19.695 20.8 19.9333V21.6667C20.8 21.905 20.995 22.1 21.2333 22.1H22.9667C23.205 22.1 23.4 21.905 23.4 21.6667V19.9333C23.4 19.695 23.205 19.5 22.9667 19.5ZM22.5333 21.2333H21.6667V20.3667H22.5333V21.2333Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.9 19.5H18.6333C18.8717 19.5 19.0667 19.695 19.0667 19.9333V21.6667C19.0667 21.905 18.8717 22.1 18.6333 22.1H16.9C16.6617 22.1 16.4667 21.905 16.4667 21.6667V19.9333C16.4667 19.695 16.6617 19.5 16.9 19.5ZM17.3333 21.2333H18.2V20.3667H17.3333V21.2333Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.2333 1.73333H23.8333C25.0304 1.73333 26 2.70292 26 3.9V23.8333C26 25.0304 25.0304 26 23.8333 26H2.16667C0.969583 26 0 25.0304 0 23.8333V3.9C0 2.70292 0.969583 1.73333 2.16667 1.73333H4.76667V1.3C4.76667 0.579583 5.34625 0 6.06667 0C6.78708 0 7.36667 0.579583
        7.36667 1.3V1.73333H11.7V1.3C11.7 0.579583 12.2796 0 13 0C13.7204 0 14.3 0.579583 14.3 1.3V1.73333H18.6333V1.3C18.6333 0.579583 19.2129 0 19.9333 0C20.6537 0 21.2333 0.579583 21.2333 1.3V1.73333ZM19.9333 0.866667C19.695 0.866667 19.5 1.06167 19.5 1.3V3.9C19.5 4.13833 19.695 4.33333 19.9333 4.33333C20.1717 4.33333
        20.3667 4.13833 20.3667 3.9V1.3C20.3667 1.06167 20.1717 0.866667 19.9333 0.866667ZM13 0.866667C12.7617 0.866667 12.5667 1.06167 12.5667 1.3V3.9C12.5667 4.13833 12.7617 4.33333 13 4.33333C13.2383 4.33333 13.4333 4.13833 13.4333 3.9V1.3C13.4333 1.06167 13.2383 0.866667 13 0.866667ZM6.06667 0.866667C5.82833 0.866667
        5.63333 1.06167 5.63333 1.3V3.9C5.63333 4.13833 5.82833 4.33333 6.06667 4.33333C6.305 4.33333 6.5 4.13833 6.5 3.9V1.3C6.5 1.06167 6.305 0.866667 6.06667 0.866667ZM4.76667 2.6H2.16667C1.45167 2.6 0.866667 3.185 0.866667 3.9V6.93333H25.1333V3.9C25.1333 3.185 24.5483 2.6 23.8333 2.6H21.2333V3.9C21.2333 4.62042 20.6537
        5.2 19.9333 5.2C19.2129 5.2 18.6333 4.62042 18.6333 3.9V2.6H14.3V3.9C14.3 4.62042 13.7204 5.2 13 5.2C12.2796 5.2 11.7 4.62042 11.7 3.9V2.6H7.36667V3.9C7.36667 4.62042 6.78708 5.2 6.06667 5.2C5.34625 5.2 4.76667 4.62042 4.76667 3.9V2.6ZM0.866667 23.8333C0.866667 24.5483 1.45167 25.1333 2.16667 25.1333H23.8333C24.5483
        25.1333 25.1333 24.5483 25.1333 23.8333V7.8H0.866667V23.8333Z"
              fill="black"/>
      </svg>

  )
}
