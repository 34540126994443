import React from "react";
import IconFb from "@/components/svg/IconFb";

export default function FacebookShareButton({url}) {
  return (
      <a target="_blank"
         href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}>
        <IconFb/>
      </a>
  )
}
