import React, {useRef, useState} from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';
import Calendar from '@/components/svg/Calendar';

const cx = classNames.bind(styles);

export default function DateField({
                                    className,
                                    placeholder,
                                    value,
                                    onChange,
                                    onValueChange,
                                    required,
                                    min,
                                    max,
                                  }) {
  const ref = useRef();
  const [error, setError] = useState(null);

  if (ref.current) {
    if (error) {
      ref.current.setCustomValidity(error);
    } else {
      ref.current.setCustomValidity('');
    }
  }

  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }
    if (onValueChange) {
      onValueChange(e.target.value)
    }
  }

  return (
      <div className={cx(styles.container)}>
        <input className={styles.invisible} value={value || ""} required={required} onChange={handleChange} ref={ref}/>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
              value={value || null}
              onChange={onValueChange}
              placeholder={placeholder}
              disableFuture
              helperText={null}
              onError={setError}
              autoOk="true"
              format="dd/MM/yyyy"
              required={required}
              // Setting min and max date for the picker
              minDate={min}
              maxDate={max}
              // Setting custom error messages
              minDateMessage={`Date must be greater than or equal to ${min}`}
              maxDateMessage={`Date must be less than or equal to ${max}`}
              invalidDateMessage={'Please enter a valid date'}
              // Disabling underline
              InputProps={{
                disableUnderline: true,
              }}
              keyboardIcon={<Calendar className={styles.calendar}/>}
              disableToolbar
              className={classNames(styles.picker, className)}
          />
        </MuiPickersUtilsProvider>
      </div>
  )

}
