import {flatten} from "lodash";

export default function getLowestPrice(products) {
  const digitalProducts = products.filter(x => ["FULL_ACCESS", "DIGITAL"].includes(x.format));
  const digitalPrices = flatten(digitalProducts.map(x => x.prices))
      .filter(price => price.metadata?.visible_on_web?.toLowerCase() === 'true');
  const weeklyAmounts = digitalPrices.map(getWeeklyAmount).sort();
  return weeklyAmounts[0];
}

function getWeeklyAmount(price) {
  if (price.recurring.interval === "day") {
    return price.unitAmount * 7 / price.recurring.intervalCount;
  }
  if (price.recurring.interval === "week") {
    return price.unitAmount / price.recurring.intervalCount;
  }
  if (price.recurring.interval === "month") {
    return price.unitAmount * 12 / (52 * price.recurring.intervalCount);
  }
  if (price.recurring.interval === "year") {
    return price.unitAmount / (52 * price.recurring.intervalCount);
  }
}
