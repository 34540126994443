import React from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';
import {useRouter} from "next/router";
import Arrow from "@/components/svg/Arrow";

const cx = classNames.bind(styles);

export default function BackButton({className,onClick}) {
  const router = useRouter();
  const handleClick = () => {
    if(onClick) {
      onClick();
    } else {
      router.back();
    }
  }
  return (
      <span className={cx(styles.container,className)} onClick={handleClick}>
        <Arrow className={styles.arrow}/>
        Back
      </span>
  )
}
