import React, {useEffect, useState} from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames';
import TextInput from "@/components/input/text/TextInput";

export default function NumericInput({
                                       className,
                                       name,
                                       defaultValue,
                                       required,
                                       min,
                                       max,
                                       value,
                                       onValueChange,
                                       placeholder
                                     }) {

  const [myValue, setMyValue] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const newValue = e.target.value === ''
        ? null
        : parseInt(e.target.value);

    if (isNaN(newValue)) {
      return;
    }

    setMyValue(newValue);
    if (onValueChange) {
      onValueChange(newValue)
    }
  }

  useEffect(() => {
    if (myValue < min) {
      setError(`Value must be greater than or equal to ${min}`)
    } else if (myValue > max) {
      setError(`Value must be less than or equal to ${max}`)
    } else {
      setError('');
    }
  }, [myValue])

  const getValue = () => value || myValue;

  const formatValue = (value) => value == null ? '' : value;

  return (
      <TextInput
          name={name}
          error={error}
          placeholder={placeholder}
          defaultValue={defaultValue}
          required={required}
          value={formatValue(getValue())}
          onChange={handleChange}
          className={classNames(styles.container, className)}
      />
  )
}
