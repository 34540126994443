import React from "react";

export default function CheckmarkTrue({className}) {
  return (
      <svg
          viewBox="0 0 16 16"
          fill="none"
          className={className}>
        <rect
            width="16"
            height="16"
            rx="8"
            fill="#27AE60"/>
        <path
            d="M4 8.375L6.66667 11L11.2 4.875"
            stroke="white"
            strokeLinecap="round"/>
      </svg>
  )
}
