import React from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';
import {format, parseISO} from 'date-fns';

const cx = classNames.bind(styles);

export default function StoryMeta({story, className}) {

  const publishedAt = story.publishedAt && format(
      parseISO(story.publishedAt),
      'dd.MM.yyyy'
  )

  return (
      <div className={cx(styles.storyMeta, className)}>
        {story.author &&
        <div className={classNames(styles.author)}>
          {story.author.image && <img src={story.author.image.url}
                                      className={styles.portrait}/>}
          <div className={styles.details}>
            {story.author?.name && !story.author?.hide && <div>
              Author, <span className={styles.name}>{story.author.name}</span>
            </div>}
            <div className={styles.publishedAt}>
              {publishedAt}
            </div>
          </div>
        </div>}
      </div>
  )
}
