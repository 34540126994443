import React, {useEffect, useRef, useState} from "react";
import styles from './Styles.module.scss';
import BackButton from "@/components/button/back/BackButton";
import ReactPlayer from "react-player/soundcloud";
import SwiperCore, {Navigation, Pagination, Scrollbar} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import BlockContent from '@sanity/block-content-to-react';
import {storyToPath} from "@/lib/utils/storyUtils";
import XPlatformAd from "@/components/ad/xplatform/XPlatformAd";
import {getRandom, getRandomNode, SITE_URL} from "@/lib/utils/utils";
import classNames from 'classnames/bind';
import Link from "next/link";
import {isDigitalSubscribed} from "@/lib/utils/authUtils";
import {categoryHierarchyArray, categoryToPath} from "@/lib/utils/categoryUtils";
import StoryMeta from "@/components/story/meta/StoryMeta";
import {useContext} from "@/lib/utils/context";
import ArticleSchema from "@/components/schema/ArticleSchema";
import DynamicForm from "@/components/form/dynamic/DynamicForm";
import FacebookShareButton from "@/components/button/facebookShare/FacebookShareButton";
import BreadcrumbSchema from "@/components/schema/BreadcrumbSchema";
import Head from 'next/head';
import SignupWidget from "@/components/widgets/signup/SignupWidget";
import NewsletterSignupWidget from "@/components/widgets/newsletterSignup/NewsletterSignupWidget";
import TrendingStories from "@/components/widgets/trendingStories/TrendingStories";
import SignUpGateSnippet from "@/components/story/signupGate/snippet/SignUpGateSnippet";
import SignUpGateWidget from "@/components/story/signupGate/widget/SignUpGateWidget";
import StickyArticleCounter from "@/components/story/articleCounter/StickyArticleCounter";
import {addFreeStoryId, logReadStoryEvent} from "@/lib/api/api";
import useOnScreen from "@/lib/utils/useOnScreen";
import {shuffle} from "lodash";
import {getRecentStories, getRecentStoriesByCategory} from "@/lib/api/sanity/api";
import StoryList from "@/components/widgets/storyList/StoryList";
import Button from "@/components/button/Button";
import CategoryLink from "@/components/link/categoryLink/CategoryLink";
import LazyImage from "../lazyImage/LazyImage";

const cx = classNames.bind(styles);

SwiperCore.use([Navigation, Pagination, Scrollbar]);

const DATA_PAGE_SIZE = 12;
const PRE_FETCH_BUFFER = 12;

const minWordsBetweenAds = 260;
const minBlocksBetweenAds = 8;
const gatedWordCount = 80;
const gatedBlockCount = 1;

const serializers = {
  types: {
    ad: renderAd,
    captionedImage: renderCaptionedImage,
    instagram: renderInstagram,
    gallery: renderGallery,
    soundcloud: renderSoundCloud,
    youtube: renderYouTube,
    bodyForm: renderForm,
  },
  marks: {
    link: ({mark, children}) => <a href={mark.href} target={'_blank'}>{children}</a>,
    inlineIcon: () => null,

    // Not using this now but keeping it here for use in banners later
    // inlineIcon: ({mark}) => <img height={'20px'} src={mark.url}/>,
  }
}

export default function Story({story, category}) {
  const {
    auth,
    user,
    setUser,
    site,
    config,
    anonStories,
    setAnonStories,
  } = useContext();
  const [body, setBody] = useState(story.body);
  const {preview, articleLimits, latestPageSize} = config;

  const scrollRef = useRef({});
  const article = useRef(null);
  const widgets = useRef(null);
  const columns = [
    {elementRef: article, offsetRef: useRef()},
    {elementRef: widgets, offsetRef: useRef()},
  ]

  const signUpWidgetRef = useRef();
  const isSignUpWidgetOnScreen = useOnScreen(signUpWidgetRef);

  // extra logic make premium content free after a certain date
  const currentDate = new Date();
  const premiumEndDate = story.premiumEndDate && new Date(story.premiumEndDate);
  const premium = story.premium && (!premiumEndDate || premiumEndDate > currentDate);
  const isPrintHero = site.printHero?.id === story.id;

  const allowAccess = preview
      || !premium
      || isDigitalSubscribed(user)
      || (user && (user.freeStoryIds ?? []).some(x => x === story.id))
      || (!user && (anonStories ?? []).some(x => x.id === story.id));

  const showGate = !allowAccess;

  const showSignupWidget = auth
      && !isDigitalSubscribed(user)
      && (user || allowAccess);

  const showArticleCounter = auth
      && !isSignUpWidgetOnScreen
      && !isDigitalSubscribed(user)
      && user;

  const {campaigns} = useContext();
  const storyPath = storyToPath(story);
  const storyUrl = `${SITE_URL}/${storyPath}`;
  const breadcrumb = [
    {
      slug: '/',
      title: `${site.name} Home`
    },
    ...categoryHierarchyArray(category)
  ];

  const schemaMarkupCrumbs = [{
    name: category?.title,
    path: categoryToPath(category),
  }, {
    name: story.headline,
    path: storyPath,
  }]

  const getWordCount = (node) => {
    return node._type === 'block'
        ? node.children?.map(x => x.text.trim()).join(' ').split(' ').length || 0
        : minWordsBetweenAds / minBlocksBetweenAds;
  }

  const spliceAds = ({story, campaigns, showGate}) => {
    const body = [];
    if (!story.body?.length) {
      return body;
    }
    let wordCount = 0;
    let blockCount = 0;
    let campaignIndex = getRandom(campaigns.length);
    for (let i = 0; i < story.body.length; i++) {
      const node = story.body[i];
      if (showGate && node._type != 'block') {
        continue;
      }
      body.push(node);
      blockCount++;
      wordCount += getWordCount(node);
      if (showGate
          && wordCount >= gatedWordCount
          && blockCount >= gatedBlockCount) {
        return body;
      }
      if (i == 0 || wordCount < minWordsBetweenAds || blockCount < minBlocksBetweenAds) {
        continue;
      }
      if (node.style?.match(/^h\d$/i)) {
        // // SRP-108 Don't display an ad after a heading
        continue;
      }
      if (node._type.match(/image/i)) {
        // SRP-108 Don't display an ad after an image
        continue;
      }
      const campaign = campaigns[campaignIndex++ % campaigns.length];
      if (!campaign || story.noAds) {
        continue;
      }
      blockCount = 0;
      wordCount = 0;
      body.push({
        _key: `ad_${i}`,
        _type: 'ad',
        mobile: getRandomNode(getRandomNode(campaigns.filter(x => x.mobile?.length))?.mobile),
        tablet: getRandomNode(getRandomNode(campaigns.filter(x => x.tablet?.length))?.tablet),
        desktop: getRandomNode(getRandomNode(campaigns.filter(x => x.desktop?.length))?.desktop),
      });
    }
    return body;
  }

  useEffect(() => {
    if (!anonStories?.some(x => x.id === story.id)
        && (anonStories ?? []).length < articleLimits.anon
        && !isPrintHero) {
      setAnonStories(prev => [...(prev ?? []), {id: story.id, date: new Date()}])
    }
  }, [anonStories])

  const freeStoryIds = user?.freeStoryIds || [];
  useEffect(() => {
    if (user
        && !isDigitalSubscribed(user)
        && !preview
        && !isPrintHero
        && premium
        && articleLimits.member > freeStoryIds.length
        && !freeStoryIds.includes(story.id)) {
      addFreeStoryId(story.id).then(setUser);
    }
  }, [story, user, preview, premium]);

  useEffect(() => {
    if (isDigitalSubscribed(user) && !preview && premium) {
      logReadStoryEvent(story.id).catch(console.log);
    }
  }, [story, user, preview, premium]);

  useEffect(() => {
    setBody(spliceAds({story, campaigns, showGate}))
  }, [story, campaigns, showGate]);

  const [similarStories, setSimilarStories] = useState(story.similarStories ?? []);
  const [trendingStories, setTrendingStories] = useState([]);
  const [recentStories, setRecentStories] = useState([]);
  const [latestIndex, setLatestIndex] = useState(latestPageSize);
  const excludeIds = [
    story.id,
    ...trendingStories.map(x => x.id),
    ...similarStories.map(x => x.id),
  ];
  const filteredRecentStories = recentStories.filter(x => !excludeIds.includes(x.id));
  const [loadingMoreStories, setLoadingMoreStories] = useState(false);
  const [eof, setEof] = useState(false);

  useEffect(() => {
    if (!loadingMoreStories && !eof && filteredRecentStories.length < latestIndex + PRE_FETCH_BUFFER) {
      getMoreRecentStories().catch(console.log);
    }
  }, [recentStories, latestIndex, eof, loadingMoreStories])

  useEffect(() => {
    if (trendingStories.length < 3) {
      setTrendingStories(shuffle(recentStories.filter(x => x.id !== story.id)).slice(0, 3))
    }
  }, [recentStories])

  useEffect(() => {
    if (similarStories.length < 3) {
      topUpSimilarStories().catch(console.log);
    }
  }, [similarStories])

  useEffect(() => {
    setTrendingStories([])
    setSimilarStories(story.similarStories ?? [])
  }, [story])

  async function topUpSimilarStories() {
    const stories = await getRecentStoriesByCategory({
      categoryId: category.id,
      config,
      excludeIds: [story.id]
    })
    setSimilarStories(prev => [...prev, ...shuffle(stories).slice(0, 3 - prev.length)])
  }

  async function getMoreRecentStories() {
    try {
      setLoadingMoreStories(true);
      const moreStories = await getRecentStories({
        config,
        page: recentStories.length / DATA_PAGE_SIZE,
        limit: DATA_PAGE_SIZE,
      });
      if (moreStories.length < DATA_PAGE_SIZE) {
        setEof(true);
      }
      setRecentStories((prev) => [...prev, ...moreStories]);
    } finally {
      setLoadingMoreStories(false);
    }
  }

  function onLoadMoreStories() {
    setLatestIndex(prev => prev + latestPageSize);
  }

  const renderBlockContent = (node = [], {imageOptions} = {}) => {
    return <BlockContent key={node._key}
                         blocks={node}
                         serializers={serializers}
                         imageOptions={imageOptions}/>;
  }

  //This is a hack to make the thumbnails on instagram embeds load faster.
  //It calls the function to process embeds five times, at one-second intervals, starting at page load.
  useEffect(() => {
    let count = 0;
    const intervalId = setInterval(() => {
      window?.instgrm?.Embeds.process();
      if (count++ >= 5) {
        clearInterval(intervalId);
      }
    }, 1000)
  }, [])

  function onScroll(e) {
    const navBarHeight = 129;
    const prevScrollOffset = scrollRef.current.scrollOffset || 0;
    const scrollOffset = e.target.documentElement.scrollTop;
    const scrollDiff = scrollOffset - prevScrollOffset;
    scrollRef.current.scrollOffset = scrollOffset;
    columns.forEach(({elementRef, offsetRef}) => {
      const prevWidgetsOffset = offsetRef.current || 0;
      const minWidgetsOffset = Math.min(navBarHeight, window.innerHeight - elementRef.current?.clientHeight ?? 0);
      const maxWidgetsOffset = Math.min(navBarHeight, prevWidgetsOffset - scrollDiff);
      const offset = Math.max(minWidgetsOffset, maxWidgetsOffset);
      offsetRef.current = offset;
      elementRef.current.setAttribute("style", `top: ${offset}px;`);
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const videoUrl = site.video?.file?.asset?.url;

  return (
      <div className={cx(styles.container, {loggedIn: user, showGate})}>
        <BreadcrumbSchema crumbs={schemaMarkupCrumbs}/>
        <ArticleSchema site={site} story={story} premium={premium}/>
        {showArticleCounter && (
            <div className={cx(styles.stickyArticleCounter)}>
              <StickyArticleCounter/>
            </div>)}
        <div className={styles.header}>
          <BackButton className={styles.back}/>
          <div className={styles.breadCrumbAndFeatureHeading}>
            <div className={styles.breadcrumb}>
              {breadcrumb.map(cat => (
                  <span key={cat.slug}>
                <Link href={categoryToPath(cat)}>
                  <a>{cat.title}</a>
                </Link>
              </span>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.mainGrid}>
          <div>
            <article ref={article} className={styles.column}>
              <div className={styles.articleHeader}>
                <CategoryLink className={styles.categoryHeading} category={category}/>
                <h1>{story.headline}</h1>
                {!story.author.hide && (
                    <span className={styles.author}>{story.author.name}</span>
                )}
                {story.sponsored && <div className={styles.sponsored}>Sponsored</div>}
              </div>
              <div className={styles.mainImage}>
                {renderBlockContent(story.mainContent)}
                <div className={styles.share}>
                  <div className={styles.shareHeader}>Share on</div>
                  <div className={styles.socialButtons}>
                    <FacebookShareButton url={storyUrl}/>
                  </div>
                </div>
              </div>
              <div className={styles.body}>
                {renderBlockContent(body, {imageOptions: {lazyLoad: true}})}
                {showGate && (<SignUpGateSnippet/>)}
              </div>
              <StoryMeta story={story} className={cx(styles.body, styles.meta)}/>
              {!showGate && <div className={cx(styles.body, styles.share, styles.shareFooter)}>
                <div className={styles.shareHeader}>Share on</div>
                <div className={styles.socialButtons}>
                  <FacebookShareButton url={storyUrl}/>
                </div>
              </div>}
            </article>
          </div>
          {showGate && (<SignUpGateWidget isPrintHero={isPrintHero} className={cx(styles.signupGateWidget)}/>)}
          <div className={styles.desktopSideWidgets}>
            <div ref={widgets} className={cx(styles.column, styles.widgets)}>
              {showSignupWidget && (
                  <div className={styles.signUpWidget}>
                    <div ref={signUpWidgetRef}/>
                    <SignupWidget/>
                  </div>
              )}
              {/**
               * @TODO
               * 1. Add in Half Page Ad Unit here.
               */}
              <TrendingStories stories={trendingStories}/>
              {videoUrl && (
                  <video className={styles.videoPlayer} muted autoPlay loop playsInline>
                    <source src={videoUrl} type="video/mp4"/>
                  </video>
              )}
              {/*{!user && <NewsletterSignupWidget/>}*/}
            </div>
          </div>
          <div className={styles.bottomWidgets}>
            {similarStories.length && <>
              <div className={styles.sectionHeading}>Similar articles</div>
              <StoryList stories={similarStories}/>
            </>}
            <div className={styles.sectionHeading}>Latest</div>
            <StoryList className={styles.latestStoriesContainer} stories={filteredRecentStories.slice(0, latestIndex)}/>
            {!eof && (
                <div className={styles.loadMoreStoriesButtonContainer}>
                  <Button
                      onClick={onLoadMoreStories}
                      submitting={loadingMoreStories && filteredRecentStories.length < latestIndex}>
                    More Stories
                  </Button>
                </div>
            )}
          </div>
        </div>

        <Head>
          <script async src="https://platform.instagram.com/en_US/embeds.js"/>
        </Head>
      </div>
  )
}

function renderCaptionedImage({node, options}) {
  return <div key={node._key}>
    {options?.imageOptions?.lazyLoad
        ? <LazyImage className={styles.image} image={node.image} alt={node.altTag}/>
        : <img
            className={styles.image}
            src={node.image?.url}
            alt={node.altTag}
        />
    }
    <div className={styles.caption}>
      {Array.isArray(node.caption)
          ? <BlockContent blocks={node.caption} serializers={serializers}/>
          : node.caption}
      {node.photographer?.name && (
        <p className={styles.imageAttribution}>
          Photo by {node.photographer.name}
        </p>
      )}
    </div>
  </div>
}

function renderGallery({node}) {
  return <Swiper
      key={node._key}
      spaceBetween={5}
      navigation
      pagination={{clickable: true}}
  >
    {node.images.map((node, i) => (
        <SwiperSlide key={i}>
          <div className={styles.slide}>
            {renderCaptionedImage({node})}
            {<div className={styles.dots}/>}
          </div>
        </SwiperSlide>
    ))}
  </Swiper>
}

function renderPlayer(data, className) {
  const url = data.url.match(/^https:\/\/soundcloud.com\/[^\/]+\/[^\/]+\/[^\/]+$/)
      ? data.url.replace(/\/([^\/]*)$/, '?secret_token=$1')
      : data.url;
  return (
      <div key={data._key} className={className}>
        <ReactPlayer
            className={styles.reactPlayer}
            url={url}
            controls={true}
            width={"100%"}
            height={"100%"}
        />
      </div>
  );
}

function renderYouTube({node: data}) {
  const url = new URL(data.url);
  const id = url.searchParams.get('v') ?? url.pathname.split('/')[1];
  const embedUrl = `https://www.youtube.com/embed/${id}`
  return <div key={data._key} className={styles.playerWrapper}>
    <iframe
        className={styles.reactPlayer}
        width="100%" height="100%"
        src={embedUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen/>
  </div>
}

function renderSoundCloud({node}) {
  return renderPlayer(node);
}

function renderInstagram({node}) {
  return <div key={node._key}
              className={classNames(styles.instagram)}
              dangerouslySetInnerHTML={{__html: node.embed?.html}}/>
}

function renderForm({node}) {
  return <div key={node._key} className={styles.form}>
    <DynamicForm formData={node.form} name={node.name}/>
  </div>
}

function renderAd({node}) {
  return <XPlatformAd
      key={node._key}
      className={styles.ad}
      ad={node}
  />
}
