import React, {useState} from 'react';
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';
import Link from 'next/link';
import CloseIcon from '@/components/svg/CloseIcon';
import {useContext} from '@/lib/utils/context';
import numeral from "numeral";
import getLowestPrice from "@/lib/utils/getLowestPrice";
import {useRouter} from "next/router";

const cx = classNames.bind(styles);

export default function StickyArticleCounter({className}) {
  const router = useRouter();
  const {site, user, config, products} = useContext();
  const [hideCounter, setHideCounter] = useState(false);
  const articleLimit = config.articleLimits.member;
  const articleCount = user?.freeStoryIds?.length ?? 0;
  const limitReached = articleCount >= articleLimit;

  const lowestPrice = numeral(getLowestPrice(products) / 100)
      .format('$0.00') + " per week";

  const onClose = () => {
    setHideCounter(true);
  };

  if (hideCounter) return null;

  if (limitReached)
    return (
        <div className={cx(styles.articleCountContainer, className, {limitReached})}>
          <div className={styles.text}>
            <Link href={{pathname: "/subscribe", query: {onSuccess: router.asPath}}}>
              <a>
                You have reached your free article limit.
                For more content, <strong>subscribe to {site.name}
                </strong> from <strong>{lowestPrice}.</strong>
              </a>
            </Link>
          </div>
        </div>
    );
  else
    return (
        <div className={cx(styles.articleCountContainer, className)}>
          <div className={styles.text}>
            <Link href={{pathname: "/subscribe", query: {onSuccess: router.asPath}}}>
              <a>
                You have read <strong>{articleCount} out
                of {articleLimit}</strong> free articles.
                For more content, <strong>subscribe
                to {site.name}</strong> from <strong>
                {lowestPrice}.</strong>
              </a>
            </Link>
          </div>
          <div onClick={onClose}>
            <CloseIcon className={styles.closeIcon}/>
          </div>
        </div>
    );
}
