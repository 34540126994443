import React, {useEffect, useRef} from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';
import Checkmark from "@/components/input/checkmark/Checkmark";

const cx = classNames.bind(styles);

export default function RadioButton({
                                      className,
                                      options,
                                      value,
                                      onValueChange,
                                      required
                                    }) {

  const onClick = (value) => {
    if (onValueChange) {
      onValueChange(value);
    }
  }

  return <div className={cx(styles.container, className)}>
    <input className={styles.invisible} value={value || ""} onChange={() => {}} required={required}/>
    {options.map(option => {
      const selected = value === option.value;
      return (
          <div key={option.value} className={cx(styles.option, {selected})} onClick={() => onClick(option.value)}>
            <div className={styles.select}>
              <Checkmark checked={value === option.value} className={styles.check}/>
              <span className={styles.label}>
              {option.label}
            </span>
            </div>
          </div>
      )
    })}
  </div>;
}
