import React, {useEffect} from "react";
import {useRouter} from 'next/router';
import Layout from "@/components/layout/Layout";
import getStaticPropsWrapper from "@/lib/utils/getStaticPropsWrapper";
import Story from "@/components/story/Story";
import {categoryToPath} from "@/lib/utils/categoryUtils";
import Category from "@/components/category/Category";
import {
  getCategoriesBySite,
  getCategoryById,
  getFormById,
  getRecentStories,
  getSite,
  getStoryBySlug, urlFor,
} from "@/lib/api/sanity/api";
import {getInstagramEmbed} from "@/lib/api/facebook/facebookApi";
import getImageUrls from "@/lib/utils/getImageUrls";
import {getStorySitePath, storyToPath} from "@/lib/utils/storyUtils";
import {uniq} from "lodash";
import {removeUndefined} from "@/lib/utils/utils";
import getConfig from "next/config";

const {publicRuntimeConfig} = getConfig();

export default function Page({redirect, category, story, hero}) {
  const router = useRouter()

  useEffect(() => {
    if (redirect) {
      router.push(redirect);
    }
  }, [redirect])

  if (redirect) {
    return <Layout nav loading/>
  }

  const title = story?.headline ?? category?.title;
  const description = story?.excerpt;
  const image = urlFor(story?.thumbnail?.image).url();

  function getCanonical() {
    if (story?.allCategories?.length) {
      const canonicalSite = story.allCategories[0].site;
      const canonicalSiteUrl = publicRuntimeConfig.sites[canonicalSite]?.siteUrl;
      const canonicalPath = getStorySitePath({story, site: canonicalSite});
      return `${canonicalSiteUrl ?? ''}/${canonicalPath}`;

    }
    return publicRuntimeConfig.siteUrl + router.asPath;
  }

  const meta = {
    title,
    description,
    og: {
      title,
      description,
      image,
    },
    twitter: {
      title,
      description,
      image,
    },
    canonical: getCanonical(),
  }

  return (
      <Layout nav meta={meta}>
        {story
            ? <Story story={story} category={category}/>
            : <Category category={category} hero={hero}/>}
      </Layout>
  )
}

const getInstagramEmbeds = async (story) => {
  return story && {
    ...story,
    body: story.body && await Promise.all(
        story.body.map(async (block) =>
            block?._type === 'instagram'
                ? {
                  ...block,
                  embed: await getInstagramEmbed(block.url),
                }
                : block
        )
    )
  }
}

const getForms = async ({story, config}) => {
  return story && {
    ...story,
    body: story.body && await Promise.all(
        story.body.map(async (block) =>
            block?._type === 'bodyForm'
                ? {
                  ...block,
                  form: await getFormById({id: block.form._ref, config}),
                  name: block.name,
                }
                : block
        )
    )
  }
}

export async function getStaticPaths() {

  const sitePromise = getSite();
  const categoriesPromise = getCategoriesBySite();
  const storiesPromise = getRecentStories({limit: 50});

  const site = await sitePromise;
  const categories = await categoriesPromise;
  const stories = await storiesPromise;

  const paths = uniq([
    ...categories.map(c => `/${categoryToPath(c)}`),
    `/${storyToPath(site.hero)}`,
    ...stories.map(s => `/${storyToPath(s)}`),
  ]);
  return {
    paths,
    fallback: true,
  };
}

export const getStaticProps = getStaticPropsWrapper(async ({wrapperProps, params}) => {

  const {site, config, categories} = wrapperProps.props;
  const {preview} = config;
  const {slugs} = params;

  function getCategoryFromSlugs(slugs) {
    const path = slugs.join('/');
    return categories.find(c => categoryToPath(c) === path);
  }

  const category = getCategoryFromSlugs(slugs);
  if (category) {
    return getCategoryProps({category, config});
  }

  const slug = slugs[slugs.length - 1];
  const story = await getStoryBySlug({slug, config});
  const storyCategory = getCategoryFromSlugs(slugs.slice(0, slugs.length - 1))
  if (story && (storyCategory || preview)) {
    return getStoryProps({site, story, config, category: storyCategory});
  }

  const redirect = slugs.slice(0, slugs.length - 1).join('/') || "/";
  return {
    props: {redirect}
  }
})

async function getCategoryProps({category, config}) {
  const {hero = null} = await getCategoryById({
    categoryId: category.id,
    config
  });
  return {
    props: {
      category,
      hero,
    }
  }
}

async function getStoryProps({category, story, config}) {
  story = await getInstagramEmbeds(story);
  story = await getForms({story, config});

  story = getImageUrls(story, {width: 700});
  story.mainContent = getImageUrls(story.mainContent, {width: 1500});
  story.author = getImageUrls(story.author, {width: 200, height: 200});

  return {
    props: {
      story: removeUndefined(story),
      category: category ?? null,
    }
  }
}
