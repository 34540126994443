import React, {useEffect, useState} from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';
import {useContext} from "@/lib/utils/context";
import XPlatformAd from "@/components/ad/xplatform/XPlatformAd";
import {getRecentStories, getRecentStoriesByCategory} from "@/lib/api/sanity/api";
import BlockContent from "@sanity/block-content-to-react";
import Button from "@/components/button/Button";
import StoryCard from "@/components/card/story/StoryCard";
import useRandom from "@/lib/utils/useRandom";

const cx = classNames.bind(styles);

const PAGE_SIZE = 12;

export default function TrendingNow({isHome, className, categoryId, hero}) {

  const {config, campaigns} = useContext();
  const [hasMore, setHasMore] = useState(true);
  const [stories, setStories] = useState([]);
  const [homepageTakeover, setHomepageTakeover] = useState(null);
  const [page, setPage] = useState(0);
  const [latestIndex, setLatestIndex] = useState(PAGE_SIZE);

  useEffect(() => {
    setStories([]);
    setPage(0);
    setLatestIndex(PAGE_SIZE);
    setHasMore(true);
  }, [categoryId]);

  const rand = useRandom();

  function getRandom(i) {
    return Math.floor(rand() * i);
  }

  function getRandomNode(nodes) {
    if (!nodes?.length) {
      return null;
    }
    const randomIndex = getRandom(nodes.length);
    return nodes[randomIndex];
  }

  const getHomepageTakeoverCampaign = () => {
    const homepageTakeoverCampaigns = campaigns.filter(c => c.homepageTakeover && c.homepageTakeover.length);
    return homepageTakeoverCampaigns.length && getRandomNode(homepageTakeoverCampaigns);
  }

  const getHomepageTakeover = () => {
    const campaign = getHomepageTakeoverCampaign();
    return campaign && getRandomNode(campaign.homepageTakeover);
  }

  const selectAd = () => {
    const homepageTakeoverCampaign = isHome && getHomepageTakeoverCampaign();
    if (homepageTakeoverCampaign) {
      return {
        type: 'ad',
        mobile: getRandomNode(homepageTakeoverCampaign.mobile),
        tablet: getRandomNode(homepageTakeoverCampaign.tablet),
        desktop: getRandomNode(homepageTakeoverCampaign.desktop),
      }
    }
    return {
      type: 'ad',
      mobile: getRandomNode(getRandomNode(campaigns.filter(x => x.mobile?.length))?.mobile),
      tablet: getRandomNode(getRandomNode(campaigns.filter(x => x.tablet?.length))?.tablet),
      desktop: getRandomNode(getRandomNode(campaigns.filter(x => x.desktop?.length))?.desktop),
    };
  }

  useEffect(() => {
    if (isHome) {
      setHomepageTakeover(getHomepageTakeover());
    }
  }, [campaigns]);

  function AdAndStory({story, adClassName}) {
    return <>
      <XPlatformAd className={classNames(styles.ad, adClassName)}
                   ad={selectAd()}
                   homepageTakeover={homepageTakeover}/>
      <StoryCard story={story}/>
    </>
  }


  useEffect(() => {
    if(hasMore) {
      getMoreStories().catch(console.log);
    }
  }, [page, hasMore])

  useEffect(() => {
    if (stories.length < latestIndex + PAGE_SIZE) {
      setPage(prev => prev + 1);
    }
  }, [stories, latestIndex])

  const getMoreStories = async () => {
    const excludeIds = [hero?.id];
    const moreStories = categoryId
        ? await getRecentStoriesByCategory({
          categoryId,
          config,
          page,
          excludeIds,
        })
        : await getRecentStories({
          config,
          page,
          excludeIds,
        });
    if(moreStories.length == 0) {
      setHasMore(false);
    }
    setStories((prev) => [...prev, ...moreStories]);
  };

  function onLoadMoreStoriesClick() {
    setLatestIndex(prev => prev + PAGE_SIZE);
  }

  const serializers = {
    types: {},
    marks: {link: ({mark, children}) => <a href={mark.href}>{children}</a>}
  }

  const renderBlockContent = (node) => {
    if (!node) {
      return undefined;
    }

    if (Array.isArray(node)) {
      return node.map(renderBlockContent);
    }
    const serializer = serializers.types[node._type];
    return serializer
        ? serializer({node})
        : <BlockContent key={node._key}
                        blocks={node}
                        serializers={serializers}/>;
  }

  return <>
    <div className={cx(styles.container, {homepageTakeover}, className)}>
      <div className={styles.trendingNowContainer}>
        {stories.slice(0, latestIndex).map((story, i) => (
            <AdAndStory key={story.id}
                        story={story}
                        adClassName={cx({
                          mobile: i % 1 == 0,
                          tablet: i % 2 == 0,
                          desktop: i % 3 == 0,
                        })}
            />
        ))}
      </div>
      <div className={styles.buttonContainer}>
        {hasMore && <Button
            onClick={onLoadMoreStoriesClick}>
          More Stories
        </Button>}
      </div>
    </div>
  </>
}
