import React from "react";
import styles from './Styles.module.scss';
import BackButton from "@/components/button/back/BackButton";
import BlockContent from "@sanity/block-content-to-react";
import TrendingNow from "@/components/trendingNow/TrendingNow";
import BreadcrumbSchema from "@/components/schema/BreadcrumbSchema";
import {categoryToPath} from "@/lib/utils/categoryUtils";
import StoryCard from "@/components/card/story/StoryCard";

export default function Category({ category, hero }) {

  const serializers = {
    marks: { link: ({ mark, children }) => <a href={mark.href} target={'_blank'}>{children}</a> }
  }

  const crumbs = [{
    name: category.title,
    path: categoryToPath(category),
  }]

  return (
    <div className={styles.container}>
      <BreadcrumbSchema crumbs={crumbs}/>
      <div className={styles.header}>
        <BackButton className={styles.back} />
        <h1>{category.title}</h1>
        {category.description && (
          <div className={styles.description}>
            <BlockContent blocks={category.description} serializers={serializers} />
          </div>
        )}
      </div>
      {hero && <StoryCard hero
        key={hero.id}
        className={styles.hero}
        story={hero} />}
      <div>
        <TrendingNow className={styles.trendingNow}
                     categoryId={category.id}
                     hero={hero} />
      </div>
    </div>
  )
}
