import React from "react";
import styles from "./Styles.module.scss";
import classNames from 'classnames/bind';
import CheckmarkTrue from "@/components/svg/CheckmarkTrue";
import CheckmarkFalse from "@/components/svg/CheckmarkFalse";

const cx = classNames.bind(styles);

export default function Checkmark({className,checked}) {
  return (
      <div className={cx(styles.checkmark,className)}>
        {checked ? <CheckmarkTrue className={styles.inner}/> : <CheckmarkFalse className={styles.inner}/>}
      </div>
  );
}
