import React from 'react';
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';
import Link from 'next/link';
import Button from '@/components/button/Button';
import {useContext} from "@/lib/utils/context";
import numeral from "numeral";
import getLowestPrice from "@/lib/utils/getLowestPrice";
import {useRouter} from "next/router";
import {toWords} from "number-to-words";

const cx = classNames.bind(styles);

export default function SignUpGateWidget({className, isPrintHero}) {
  const router = useRouter();
  const {user, config, products, site} = useContext();
  const articleLimit = config.articleLimits.member;
  const limitReached = user?.freeStoryIds?.length >= articleLimit;

  const lowestPrice = numeral(getLowestPrice(products) / 100)
      .format('$0.00') + "/week";

  // JSX returned when user isn't logged in.
  const createAccountGate = () => {
    return (
        <>
          <h2 className={styles.keepReading}>Keep reading with one of these options:</h2>
          <div className={styles.optionsContainer}>
            <div className={styles.optionContainer}>
              <h2>Access to {articleLimit} free articles</h2>
              <strong className={styles.price}>Free</strong>
              <p>
                Subscribe now or create an account to enjoy a taste
                of {toWords(articleLimit)} articles for free.
              </p>
              <Link href={{pathname: "/signup", query: {onSuccess: router.asPath}}}>
                <a>
                  <Button solid className={styles.button}>
                    Sign up
                  </Button>
                </a>
              </Link>
            </div>
            <div className={styles.optionContainer}>
              <h2>Unlimited access</h2>
              <strong className={styles.price}>From {lowestPrice}</strong>
              <p>
                Enjoy all you can read for one low weekly price. To get more {site.name}&nbsp;Magazine
                in your life, choose your favourite package now.
              </p>
              <Link href={{pathname: "/subscribe", query: {onSuccess: router.asPath}}}>
                <a>
                  <Button solid className={styles.button}>
                    Create account
                  </Button>
                </a>
              </Link>
            </div>
          </div>
        </>
    );
  };

  // JSX returned when user has logged in and reached their article limit.
  const premiumMemberGate = () => {
    return (
        <>
          <h2>
            {isPrintHero
                ? `This is a premium members only article. To continue reading please subscribe to become a premium member.`
                : `To continue reading our content, please subscribe to become a premium member`}
          </h2>
          <div className={styles.premiumContainer}>
            <h2>Unlimited access</h2>
            <strong>From {lowestPrice}</strong>
            <p>
              Subscribe now to treat yourself to unlimited access to our amazing articles and exclusive perks.
            </p>
            <strong>Includes</strong>
            <p>
              Unlimited access to articles
            </p>
            <p>
              Exclusive content and offers
            </p>
            <Link href="/subscribe">
              <a>
                <Button black className={styles.button}>
                  subscribe
                </Button>
              </a>
            </Link>
          </div>
        </>
    );
  };

  return (
      <div className={cx(styles.container, className, {limitReached})}>
        <div className={styles.loginLink}>
          Already have an account?{'  '}
          <Link href="/login">
            <a>Log in</a>
          </Link>
        </div>
        {limitReached || isPrintHero ? premiumMemberGate() : createAccountGate()}
      </div>
  );
}
