import React from "react";
import {JsonLd} from "react-schemaorg";
import {SITE_URL} from "@/lib/utils/utils";

export default function ArticleSchema({site, story, premium}) {
  return (
      <JsonLd
          item={{
            "@context": "https://schema.org",
            "@type": "Article",
            datePublished: story.publishedAt,
            isAccessibleForFree: !premium,
            headline: story.headline,
            image: story.thumbnail?.image?.url,
            author: {
              "@type": "Person",
              name: story.author?.name,
              image: story.author?.image?.url
            },
            publisher: {
              "@type": "Organization",
              name: `${site.name} Magazine`,
              logo: {
                "@type": "ImageObject",
                url: `${SITE_URL}/images/${site.slug}/schema-logo.png`
              }
            }
          }}
      />
  );
}
