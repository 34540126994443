import React from "react";

export default function CheckmarkFalse({className}) {
  return (
      <svg
          viewBox="0 0 16 16"
          fill="none"
          className={className}>
        <rect
            x="0.5"
            y="0.5"
            width="15"
            height="15"
            rx="7.5"
            stroke="currentColor"/>
      </svg>
  )
}
