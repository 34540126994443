import React, {useState} from "react";
import styles from './Styles.module.scss';
import classNames from "classnames/bind";
import Button from "@/components/button/Button";
import TextInput from "@/components/input/text/TextInput";
import EmailInput from "@/components/input/email/EmailInput";
import CheckboxInput from "@/components/input/checkbox/CheckboxInput";
import {submitForm} from "@/lib/api/api";
import {useContext} from "@/lib/utils/context";
import RadioButton from "@/components/button/radiobutton/RadioButton";
import DateField from "@/components/input/dateField/DateField";
import SelectField from "@/components/input/select/SelectField";
import NumericInput from "@/components/input/numericInput/NumericInput";

export default function DynamicForm({formData, name, className}) {

  const [formValues, setFormValues] = useState({});
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    await submitForm({id: name, payload: formValues});
    setSubmitting(false);
    setSuccess(true);
  }

  const renderField = (field) => {
    switch (field._type) {
      case "textField":
        return renderTextField(field);
      case "numberField":
        return renderNumberField(field);
      case "emailField":
        return renderEmailField(field);
      case "dateField":
        return renderDateField(field);
      case "checkboxField":
        return renderCheckboxField(field);
      default:
        return;
    }
  }

  const renderOptions = (field) => {
    return field.layout === 'radio'
        ? renderRadio(field)
        : renderSelect(field);
  }

  const renderTextField = (field) => {
    return (field.options && field.options.length)
        ? renderOptions(field)
        : renderFreeTextField(field);
  }

  const renderFreeTextField = ({_key, label, required}) => {
    return <div key={_key} className={styles.field}>
      {/*Label if needed to be displayed */}
      {/*<label>{label}*/}
      <TextInput
          name={label}
          placeholder={label}
          required={required}
          onValueChange={value => setFormValues({...formValues, [label]: value})}
      />
      {/*</label>*/}
    </div>
  }

  const renderRadio = ({_key, label, required, options}) => {

    const value = formValues[label]

    return <div key={_key} className={styles.field}>
      {/*Label if needed to be displayed */}
      {/*<label>{label}*/}
      <RadioButton
          className={styles.radio}
          name={label}
          options={options}
          value={value}
          required={required}
          onValueChange={value => setFormValues({...formValues, [label]: value})}
          required={required}
      />
      {/*</label>*/}
    </div>
  }

  const renderSelect = ({_key, label, required, options}) => {

    const value = formValues[label]

    return <div key={_key} className={styles.field}>
      {/*Label if needed to be displayed*/}
      {/*<label>{label}*/}
      <SelectField
          name={label}
          placeholder={label}
          value={value}
          onValueChange={value => setFormValues({...formValues, [label]: value})}
          required={required}
          options={required ? options : [{}, ...options]}>
      </SelectField>
      {/*</label>*/}
    </div>
  }

  const renderEmailField = ({_key, label, required}) => {
    return <div key={_key} className={styles.field}>
      {/*Label if needed to be displayed*/}
      {/*<label>{label}*/}
      <EmailInput
          name={label}
          placeholder={label}
          required={required}
          onValueChange={value => setFormValues({...formValues, [label]: value})}
      />
      {/*</label>*/}
    </div>
  }

  const renderNumberField = ({_key, label, required, min, max, pattern}) => {

    const value = formValues[label]

    return <div key={_key} className={styles.field}>
      {/*Label if needed to be displayed*/}
      {/*<label>{label}*/}
      <NumericInput
          name={label}
          placeholder={label}
          required={required}
          pattern={pattern}
          min={min}
          max={max}
          value={value}
          onValueChange={value => setFormValues({...formValues, [label]: value})}
      />
      {/*</label>*/}
    </div>
  }

  const renderDateField = ({_key, label, required, min, max}) => {

    const value = formValues[label]

    return <div key={_key} className={styles.field}>
      {/* Label if needed to be displayed */}
      {/*<label>{label}*/}
      <DateField
          name={label}
          placeholder={label}
          type={"date"}
          required={required}
          min={min}
          max={max}
          value={value}
          onValueChange={value => setFormValues({...formValues, [label]: value})}
      />
      {/*</label>*/}
    </div>
  }

  const renderCheckboxField = ({_key, label, required}) => {
    return <div key={_key} className={styles.field}>
      <CheckboxInput
          name={label}
          required={required}
          onValueChange={value => setFormValues({...formValues, [label]: value})}
      >
        {label}
      </CheckboxInput>
    </div>
  }

  return (
      <form className={classNames(styles.container, className)}
            onSubmit={onFormSubmit}>
        {formData.fields.map(renderField)}
        <div className={styles.buttonContainer}>
          <Button done={success} submitting={submitting} className={styles.submitButton} solid>{formData.submitButtonText}</Button>
        </div>
      </form>
  )
}
