import styles from './Styles.module.scss';
import classNames from 'classnames';
import React from 'react'
import Select from 'react-select'

const customStyles = {
  container: (base) => ({
    ...base,
    height: "100%",
  }),
  control: (base) => ({
    ...base,
    // styling for the main field
    background: "none",
    paddingLeft: 10,
    border: "none",
    height: "100%",
    cursor: "pointer",
  }),
  placeholder: (base) => ({
    ...base,
    // placeholder styling
    // correct color for placeholders
    color: "#757575",
    fontWeight: 400,
  }),
  option: (base, state) => ({
    ...base,
    // option text color
    color: state.isSelected ? "black" : "black",
    fontWeight: state.isSelected ? 600 : 300,
    height: 40,
    paddingLeft: 20,
    background: "none",
    "&:hover": {
      background: "#F9F8F5",
    },
  }),
  menu: base => ({
    ...base,
    // kill the gap
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
  })
};

export default function SelectField({
                                      className,
                                      placeholder,
                                      options,
                                      value,
                                      onValueChange,
                                      required
                                    }) {

  function customTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#FFF',
        primary: '#EEE',
      }
    }
  }

  const handleChange = (e) => {
    if (onValueChange) {
      onValueChange(e.value)
    }
  }

  return (
      <div className={classNames(styles.container, className)}>
        <input className={styles.invisible} value={value || ""} onChange={() => {}} required={required}/>
        <Select
            value={value ? options.find(option => option.value === value) : null}
            onChange={handleChange}
            styles={customStyles}
            options={options}
            placeholder={placeholder}
            theme={customTheme}
            instanceId='Select'
        />
      </div>
  );
}
