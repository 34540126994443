import LockIcon from '@/components/svg/LockIcon';
import React from 'react';
import styles from './Styles.module.scss';

export default function SignUpGateSnippet() {
  return (
    <div className={styles.signupGateSnippet}>
      <LockIcon />
      <span>content locked, see options below to continue</span>
    </div>
  )
}
